<template>
    <v-container fluid class="fill-height">
        <div class="justify-center align-center d-flex flex-column flex-grow-1">
        <v-card class="login-box ma-auto">
            <v-card-text class="text-center">
                <img src="@/assets/logo-black.png" alt="tallo verde" />
            </v-card-text>

            <v-card-text class="text-center">
                <span class="title text--lighten-1 grey--text mb-5">
                    Inicio de sesión
                </span>
            </v-card-text>

            <v-card-text>
                <v-form>
                    <v-text-field
                        outlined
                        v-model="email"
                        name="email"
                        label="email"
                        required
                        clearable
                    />

                    <v-text-field
                        outlined
                        v-model="password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        name="password"
                        label="password"
                        @click:append="showPassword = !showPassword"
                        required
                        :error-messages="errors"
                    />

                    <v-btn block x-large color="secondary" @click="onLogin">Login</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
        </div>
    </v-container>
</template>

<script>
    export default {
        components: {},

        data() {
            return {
                showPassword: false,
                email: '',
                password: '',
                errors: []
            };
        },

        mounted() {
        },

        methods: {
            async onLogin() {
                this.isLoading = true;

                try {
                    let response = await this.$store.dispatch('user/login', {
                        username: this.email,
                        password: this.password
                    });

                    if(this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect);
                    }
                    else {
                        this.$router.push({ name: 'dashboard' });
                    }
                }
                catch (err) {
                    console.log(err);
                    this.isLoading = false;

                    this.errors.push('El usuario o la contraseña son incorrectos');
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .login-box {
        width: 480px;
        box-shadow: 0 3px 10px -2px rgb(85 85 85 / 8%), 0 2px 20px 0 rgb(85 85 85 / 6%), 0 1px 30px 0 rgb(85 85 85 / 3%) !important;
    }

    .login-column {
        background-color: white;
    }
</style>
